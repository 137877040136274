import { types } from "../../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case types.REGISTRO_EXITOSO:
      return {
        ...state,
        errorForm: false,
        createForm: true,
        exitCession: false,
      };
    case types.REGISTRO_ERROR:
      return {
        ...state,
        errorFormulario: action.payload,
        errorForm: true,
        createForm: false,
        exitCession: false,
      };

    case types.LOGIN_EXITOSO:
      localStorage.setItem("token-wonderland-store", action.payload.data.token);
      localStorage.removeItem("tk-wonderland-store-anonymus");
      return {
        ...state,
        autenticado: true,
        token: action.payload.data.token,
        token_anonymus: null,
        user_anonymus: false,
        // usuarioName: action.payload.username,
        cargando: false,
        exitCession: false,
        createForm: false,
      };
    case types.LOGIN_ERROR:
      localStorage.removeItem("token-wonderland-store");
      return {
        ...state,
        token: null,
        autenticado: false,
        user_anonymus: true,
        mensaje: action.payload,
        errorLogin: true,
        usuarioData: null,
        exitCession: false,
        createForm: false,
      };
    case types.CERRAR_SESION:
      localStorage.removeItem("token-wonderland-store");
      return {
        ...state,
        token: null,
        // autenticado: false,
        // usuarioData: null,
        // mensaje: null,
        // user_anonymus: true,
        cargando: true,
        exitCession: true,
      };
    case types.RESET__ERROR:
      return {
        ...state,
        errorLogin: false,
        mensaje: null,
        errorFormulario: null,
        errorForm: false,
        createForm: false,
      };
    case types.OBTENER_USUARIO:
      localStorage.removeItem("tk-wonderland-store-anonymus");
      return {
        ...state,
        usuarioData: action.payload.data,
        autenticado: true,
        user_anonymus: false,
        cargando: action.payload.status === 200 ? false : true,
        exitCession: false,
      };

    case types.OBTENER_USUARIO_ANONIMO:
      localStorage.setItem(
        "tk-wonderland-store-anonymus",
        action.payload.data.token
      );
      localStorage.removeItem("token-wonderland-store");
      // console.log(" action.payload", action.payload);
      return {
        ...state,
        token: null,
        autenticado: false,
        usuarioData: null,
        mensaje: null,
        token_anonymus: action.payload.data.token,
        user_anonymus: true,
        error_user_anonymus: false,
        cargando: action.payload.status === 201 ? false : true,
        exitCession: false,
      };

    case types.ERROR_USUARIO_ANONIMO:
      localStorage.removeItem("tk-wonderland-store-anonymus");
      return {
        ...state,
        token_anonymus: null,
        autenticado: false,
        user_anonymus: true,
        error_user_anonymus: true,
        exitCession: false,
      };

    case types.LOADING_USUARIO_ANONIMO:
      return {
        ...state,
        cargando: false,
        exitCession: false,
      };

    case types.REGISTER_ANONIMUS:
      return {
        ...state,
        token_anonymus: null,
        user_anonymus: false,
        autenticado: false,
      };

    default:
      return state;
  }
};
