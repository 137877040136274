import { useContext, useEffect, useState } from "react";
import {
  SesionRegister,
  FormRegister,
  ContenedorRegister,
  InputForm,
  LabelForm,
  SelectForm,
  BotonForm,
} from "../styles/LoginStyles";
import { AuthContext } from "../context/auth/AuthProvider";
import { HeaderFooter } from "./HeaderFooter";
import { useNavigate } from "react-router-dom";

export const Register = () => {
  const {
    createUser,
    errorFormulario,
    ResetError,
    errorForm,
    createForm,
    RegisterAnonimus,
    iniciarSesion,
    autenticado,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (autenticado) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autenticado, navigate]);

  const [userState, setUserState] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    gender: "",
    phone: "",
    dni: "",
    birth_date: "",
    anonymus_token: localStorage.getItem("tk-wonderland-store-anonymus"),
    confirm_password: "",
  });

  const [errors, setErrors] = useState(null);
  // console.log("createForm initial", createForm);
  const {
    email,
    password,
    first_name,
    last_name,
    gender,
    phone,
    dni,
    birth_date,
    confirm_password,
  } = userState;

  const handleChange = (e) => {
    setUserState({
      ...userState,
      [e.target.name]: e.target.value,
    });
  };

  const handleRedirectoLogin = () => {
    // console.log("createForm", createForm);
    ResetError();
    if (createForm) {
      // console.log("login");
      RegisterAnonimus();
      // navigate("/login");
      ResetError();
      iniciarSesion({
        email: email,
        password: password,
      });
      navigate("/");
    }
  };

  useEffect(() => {
    handleRedirectoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createForm, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = {
      email: "Email",
      password: "Contraseña",
      first_name: "Nombres",
      last_name: "Apellidos",
      gender: "Género",
      phone: "Teléfono",
      dni: "DNI",
      birth_date: "Fecha de nacimiento",
    };

    const newErrors = {};

    // Iterar sobre los campos y establecer los que estén en blanco o no cumplan con la validación como obligatorios
    Object.entries(requiredFields).forEach(([fieldName, fieldLabel]) => {
      if (!userState[fieldName].trim()) {
        newErrors[fieldName] = `${fieldLabel} es obligatorio`;
      } else {
        // Aquí agregamos la validación específica para dni y teléfono
        if (fieldName === "dni" && userState[fieldName].length !== 8) {
          newErrors[fieldName] = `El ${fieldLabel} debe tener 8 dígitos`;
        }
        if (fieldName === "phone" && userState[fieldName].length !== 9) {
          newErrors[fieldName] = `El ${fieldLabel} debe tener 9 dígitos`;
        }
        if (fieldName === "password" && userState[fieldName].length < 5) {
          newErrors[fieldName] = `La ${fieldLabel} debe tener mas de 4 digitos`;
        }
      }
    });

    // Validar que las contraseñas sean iguales
    if (password !== confirm_password) {
      newErrors["confirm_password"] = "Las contraseñas no coinciden";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

      setTimeout(() => {
        setErrors({});
        ResetError();
      }, 5000);
      return;
    }

    createUser(userState);
    if (errorForm) {
      setTimeout(() => {
        setErrors({});
        // ResetError();
      }, 5000);
      return;
    }
  };

  return (
    <HeaderFooter searchModal={true}>
      <ContenedorRegister>
        <SesionRegister>
          <h2>Registrar usuario</h2>
          <FormRegister>
            <div>
              <LabelForm
                htmlFor="first_name"
                className={errors?.first_name ? "error-form" : ""}
              >
                Nombres *{errors?.first_name && ` - ${errors.first_name}`}
              </LabelForm>
              <div>
                <InputForm
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={first_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="last_name"
                className={errors?.last_name ? "error-form" : ""}
              >
                Apellidos *{errors?.last_name && ` - ${errors.last_name}`}
              </LabelForm>
              <div>
                <InputForm
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <LabelForm htmlFor="type">Tipo de documento *</LabelForm>
              <div>
                <SelectForm name="type" id="type" defaultValue={"DNI"}>
                  <option value="DNI">DNI</option>
                  {/* <option value="RUC">RUC</option> */}
                </SelectForm>
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="dni"
                className={errors?.dni ? "error-form" : ""}
              >
                Número de Doc. * {errors?.dni && ` - ${errors.dni}`}
              </LabelForm>
              <div>
                <InputForm
                  maxLength="8"
                  minLength="8"
                  type="number"
                  name="dni"
                  id="dni"
                  value={dni}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="gender"
                className={errors?.gender ? "error-form" : ""}
              >
                Genero * {errors?.gender && ` - ${errors.gender}`}
              </LabelForm>
              <div>
                <SelectForm
                  name="gender"
                  id="gender"
                  value={gender}
                  onChange={handleChange}
                >
                  <option value="">Elegir una opción</option>
                  <option value="HOMBRE">Masculino</option>
                  <option value="MUJER">Femenino</option>
                  <option value="NO_RESPONDE">Otro</option>
                </SelectForm>
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="birth_date"
                className={errors?.birth_date ? "error-form" : ""}
              >
                Cumpleaños *{errors?.birth_date && ` - ${errors.birth_date}`}
              </LabelForm>
              <div>
                <InputForm
                  type="date"
                  name="birth_date"
                  id="birth_date"
                  value={birth_date}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="phone"
                className={errors?.phone ? "error-form" : ""}
              >
                Celular o Teléfono* {errors?.phone && ` - ${errors.phone}`}
              </LabelForm>
              <div>
                <InputForm
                  type="number"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="email"
                className={
                  errors?.email || errorFormulario?.email ? "error-form" : ""
                }
              >
                Email *{" "}
                {(errors?.email && ` - ${errors.email}`) ||
                  (errorFormulario?.email && ` - ${errorFormulario.email}`)}
              </LabelForm>
              <div>
                <InputForm
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="password"
                className={errors?.password ? "error-form" : ""}
              >
                Contraseña *{errors?.password && ` - ${errors.password}`}
              </LabelForm>
              <div>
                <InputForm
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <LabelForm
                htmlFor="confirm_password"
                className={errors?.confirm_password ? "error-form" : ""}
              >
                Confirmar contraseña *
                {errors?.confirm_password && ` - ${errors.confirm_password}`}
              </LabelForm>
              <div>
                <InputForm
                  type="password"
                  name="confirm_password"
                  id="confirm_password"
                  value={confirm_password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <BotonForm>
              <LabelForm htmlFor="salida">&nbsp;&nbsp;&nbsp;&nbsp;</LabelForm>
              <InputForm
                type="submit"
                value="Registrar"
                className="formulario__btn"
                onClick={handleSubmit}
              />
            </BotonForm>
          </FormRegister>
        </SesionRegister>
      </ContenedorRegister>
    </HeaderFooter>
  );
};
