import Proptypes from "prop-types";
import { createContext, useReducer } from "react";
import ProductReducer from "./ProductReducer";
import { clienteAxios } from "../../config/axios";
import { types } from "../../types";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const initialState = {
    productList: [],
    errorProduct: null,
    loading: true,
    count: 0,
    totalPage: 0,
    productCaroucel: [],
    errorProductCaroucel: null,
    loadingCaroucel: true,
    categoryList: [],
    errorCategory: null,
    listCart: [],
    errorAddCart: null,
    countCart: 0,
    loadingAddCart: true,
    addCartStatus: false,
    orderStatus: false,
    orderErrorMessage: null,
    orderCart: [],
    categoryFilter: "pokemon",
    kindFilter: null,
    subkindFilter: null,
    searchProduct: "",
    eventList: [],
    productFeatured: [],
    have_coupon: null,
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  const changeCategory = ({ category }) => {
    dispatch({
      type: types.CATEGORY_FILTER_HEADER,
      payload: category,
    });
  };

  const changeKind = ({ kind }) => {
    dispatch({
      type: types.KIND_FILTER,
      payload: kind,
    });
  };

  const changeSubKind = ({ subkind }) => {
    dispatch({
      type: types.SUB_KIND_FILTER,
      payload: subkind,
    });
  };
  const changevalueSearch = ({ category, kind }) => {
    dispatch({
      type: types.SEARCH_VALUE_PRODUCT,
      payload: {
        category: category,
        kind: kind,
      },
    });
  };
  const listProductFilter = async ({
    page_size = 16,
    page = 1,
    category = "pokemon",
    kind = "",
    subkind = "",
    name = "",
  }) => {
    try {
      const respuesta = await clienteAxios.get(
        `product/products/?page_size=${page_size}&page=${page}&category=${category}&kind=${kind}&subkind=${subkind}&name=${name}`
      );

      // console.log(respuesta);
      dispatch({
        type: types.LIST_PRODUCT_FILTER,
        payload: {
          data: respuesta.data.results,
          count: respuesta.data.count,
          loading: respuesta.status === 200 ? false : true,
          params: {
            category,
            kind,
            subkind,
          },
        },
      });
      return respuesta.data;
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ERROR_LIST_PRODUCT,
      });
    }
  };

  const listProductFilterPrice = async (price, page = 1, page_size = 15) => {
    try {
      const respuesta = await clienteAxios.get(
        `/productos/price/${price}?page_size=${page_size}&page=${page}`
      );
      // console.log(respuesta);
      dispatch({
        type: types.LIST_PRODUCT_FILTER_PRICE,
        payload: respuesta,
      });
      return respuesta.data;
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ERROR_LIST_PRODUCT,
      });
    }
  };

  const getCategoryFilter = async () => {
    try {
      const respuesta = await clienteAxios.get("product/category");
      dispatch({
        type: types.LIST_CATEGORY_FILTER,
        payload: respuesta,
      });
      return respuesta.data;
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ERROR_LIST_CATEGORY_FILTER,
      });
    }
  };

  // Carrousel
  const getProductCarrousel = async () => {
    try {
      const respuesta = await clienteAxios.get("wadmin/carrousel");
      dispatch({
        type: types.LIST_PRODUCT_CAROUSEL,
        payload: respuesta,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ERROR_LIST_PRODUCT_CAROUSEL,
      });
    }
  };

  // Events
  const getEventList = async () => {
    try {
      const respuesta = await clienteAxios.get("wadmin/events");
      dispatch({
        type: types.EVENTS_LIST_SUCCESS,
        payload: respuesta,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.EVENTS_LIST_ERROR,
      });
    }
  };

  // Product featured
  const getProductFeaturedList = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "product/product-featured/?page_size=4&page1"
      );
      // console.log("respuesta", respuesta);
      dispatch({
        type: types.PRODUCT_FEATURED_LIST_SUCCESS,
        payload: respuesta,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.PRODUCT_FEATURED_ERROR,
      });
    }
  };

  //CartAnonimus

  const getListCartUser = async () => {
    try {
      const respuesta = await clienteAxios.get("cart/mycart/");
      // console.log("respuesta list cart", respuesta);
      dispatch({
        type: types.LISTAR_CART_USER,
        payload: respuesta,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ERROR_LISTAR_CART_USER,
        payload: error.response?.data.msg,
      });
    }
  };

  const addCartItem = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("cart/addcart/", datos);
      // console.log("respuesta add cart", respuesta);
      dispatch({
        type: types.AGREGAR_CARRO,
        payload: respuesta,
      });
      if (respuesta.status === 201) {
        getListCartUser();
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ERROR_AGREGAR_CARRO,
        payload: error.response,
      });
    }
  };

  const updateCartItem = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("cart/cartitemupgrade/", datos);
      if (respuesta.status === 200) {
        getListCartUser();
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const deleteCartItem = async (datos, multicart = false) => {
    try {
      const respuesta = await clienteAxios.post("cart/cartitemdestroy/", datos);
      // console.log("respuesta delete cart", respuesta);
      dispatch({
        type: types.DELETE_CART,
        payload: respuesta,
      });
      if (respuesta.status === 200 && !multicart) {
        getListCartUser();
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ERROR_DELETE_CART,
        payload: error.response,
      });
    }
  };

  const orderPayment = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("order/payment/", datos);
      // console.log("respuesta order payment", respuesta);
      dispatch({
        type: types.ORDER_PAYMENT_SUCCESS,
        payload: respuesta,
      });
      if (respuesta.status === 200) {
        checkOrderCart();
        listProductFilter({ page_size: 16 });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ORDER_PAYMENT_ERROR,
        payload: error.response,
      });
    }
  };

  const orderPaymentCulqi = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "order/new-payment-culqi/",
        datos
      );
      // console.log("respuesta order payment", respuesta);
      dispatch({
        type: types.ORDER_PAYMENT_SUCCESS,
        payload: respuesta,
      });
      if (respuesta.status === 200) {
        checkOrderCart();
        listProductFilter({ page_size: 16 });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ORDER_PAYMENT_ERROR,
        payload: error.response,
      });
    }
  };

  const checkOrderCart = async () => {
    try {
      const respuesta = await clienteAxios.get("order/checkcart/");
      // console.log("checkcart", respuesta);
      dispatch({
        type: types.ORDER_CHECK_CART_SUCCESS,
        payload: respuesta,
      });
      if (respuesta.data?.checkcart_status === "OK") {
        getListCartUser();
      } else {
        const deletePromises = respuesta.data?.checkcart_result.map((item) => {
          return deleteCartItem(
            {
              cart_item_id: item.cartitem,
            },
            true
          );
        });

        // Esperar a que todas las solicitudes de eliminación se completen
        await Promise.all(deletePromises);

        // Después de completar todas las eliminaciones, llamar a getListCartUser()
        getListCartUser();
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.ORDER_CHECK_CART_ERROR,
        payload: error.response?.data.msg,
      });
    }
  };

  const resetOrder = () => {
    dispatch({
      type: types.RESET_ORDER_PAYMENT,
    });
  };

  const resetListOrder = () => {
    dispatch({
      type: types.RESET_LIST_ORDER,
    });
  };

  //cupon
  const handleDeleteCupon = async () => {
    try {
      const respuesta = await clienteAxios.post("cart/removecoupon/", {});
      // console.log("response cupon", respuesta);
      if (respuesta.status === 200) {
        // console.log("succes");
        getListCartUser();
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <ProductContext.Provider
      value={{
        ...state,
        changeCategory,
        changeKind,
        changeSubKind,
        changevalueSearch,
        listProductFilter,
        listProductFilterPrice,
        getProductCarrousel,
        getEventList,
        getProductFeaturedList,
        getCategoryFilter,
        getListCartUser,
        addCartItem,
        updateCartItem,
        deleteCartItem,
        orderPayment,
        orderPaymentCulqi,
        resetOrder,
        checkOrderCart,
        resetListOrder,
        handleDeleteCupon,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  children: Proptypes.node,
};
