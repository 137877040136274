import Proptypes from "prop-types";
import { createContext, useReducer } from "react";
import { clienteAxios } from "../../config/axios";
import { types } from "../../types";
import AuthReducer from "./AuthReducer";
import { tokenAuth } from "../../config/tokenAuth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const initialState = {
    errorFormulario: null,
    errorForm: null,
    createForm: false,
    token: localStorage.getItem("token-wonderland-store"),
    user_anonymus: true,
    error_user_anonymus: false,
    token_anonymus: localStorage.getItem("tk-wonderland-store-anonymus"),
    autenticado: false,
    usuarioData: null,
    mensaje: null,
    cargando: true,
    errorLogin: false,
    exitCession: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const createUser = async (userCreate) => {
    try {
      const respuesta = await clienteAxios.post("account/create/", userCreate);
      console.log(respuesta);
      dispatch({
        type: types.REGISTRO_EXITOSO,
      });
      tokenAuth(false);
    } catch (error) {
      const errores = error.response.data;
      console.log(errores);
      dispatch({
        type: types.REGISTRO_ERROR,
        payload: errores,
      });
    }
  };

  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("account/token/", datos);
      dispatch({
        type: types.LOGIN_EXITOSO,
        payload: respuesta,
      });
      tokenAuth(respuesta.data.token);
      // Obtener el usuario
      usuarioAutenticado();
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: types.LOGIN_ERROR,
        payload: error.response?.data,
      });
    }
  };

  const cerrarSesion = () => {
    dispatch({
      type: types.CERRAR_SESION,
    });
  };

  const ResetError = () => {
    dispatch({
      type: types.RESET__ERROR,
    });
  };

  const usuarioAutenticado = async (retry = true) => {
    const token = localStorage.getItem("token-wonderland-store");
    if (token) {
      //Funcion para enviar el token por header
      tokenAuth(token);
      try {
        const respuesta = await clienteAxios.get("account/detail/");
        // console.log(respuesta.data);
        dispatch({
          type: types.OBTENER_USUARIO,
          payload: respuesta,
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: types.LOGIN_ERROR,
        });
      }
    } else {
      if (retry) {
        setTimeout(() => {
          usuarioAutenticado(false);
        }, 1000);
      }
    }
  };

  const usuarioAnonymus = async (anonimus = true) => {
    const token = localStorage.getItem("tk-wonderland-store-anonymus");
    const tokenWonderland = anonimus
      ? localStorage.getItem("token-wonderland-store")
      : false;

    if (tokenWonderland) {
      // console.log("tokenWonderland");
      return;
    }

    if (token) {
      //Funcion para enviar el token por header
      // console.log("token anonimo");
      tokenAuth(token);
      dispatch({
        type: types.LOADING_USUARIO_ANONIMO,
      });
      return;
    } else {
      try {
        // console.log("get anonimo");
        const respuesta = await clienteAxios.get("account/create-anonymus/");
        dispatch({
          type: types.OBTENER_USUARIO_ANONIMO,
          payload: respuesta,
        });
        tokenAuth(respuesta.data.token);
      } catch (error) {
        console.log(error);
        dispatch({
          type: types.ERROR_USUARIO_ANONIMO,
        });
      }
      return;
    }
  };

  const userClosetSeccion = () => {
    dispatch({
      type: types.LOADING_USUARIO_ANONIMO,
    });
  };

  const RegisterAnonimus = async () => {
    localStorage.removeItem("tk-wonderland-store-anonymus");
    usuarioAnonymus(false);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        createUser,
        iniciarSesion,
        cerrarSesion,
        ResetError,
        usuarioAutenticado,
        usuarioAnonymus,
        userClosetSeccion,
        RegisterAnonimus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: Proptypes.node,
};
